import * as styles from './LinkImage.styles'
import { Image } from './gassan-ui'
import { Text } from '@gassan-ui'
import { parseAll } from '@config/theme'
import { getKontentImage } from '@lib/kontent'
import { cx } from '@linaria/core'
import Link from 'next/link'
import { FC } from 'react'

type LinkImageProps = {
  variant?: 'horizontal' | 'vertical'
  href: string
  imageSource: string
  title: string
  subtitle?: string
}

export const LinkImage: FC<LinkImageProps> = ({
  variant = 'horizontal',
  href,
  imageSource,
  title,
  subtitle,
}) => {
  return (
    <div className={styles.wrap} data-variant={variant}>
      <Link href={href}>
        <a className={styles.parent}>
          <Image
            placeholderSrc={getKontentImage(imageSource, {
              width: 40,
            })}
            sources={[getKontentImage(imageSource, { width: 600 })]}
            className="absolute inset-0 z-[-1]"
            alt={subtitle || ''}
          />
          <div className={styles.content} data-content>
            <div className={styles.title} data-title>
              {title}
            </div>
          </div>
          <div className={styles.hoverContent} data-hover-content>
            <div className={cx(parseAll({ mb: subtitle ? '4' : '0' }), styles.title)}>{title}</div>
            {subtitle && <Text className="mb-0 text-white">{subtitle}</Text>}
          </div>
        </a>
      </Link>
    </div>
  )
}
